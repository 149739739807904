import { formatTime, stringToLocalPlainDateTime } from "@movehq/datetime";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import {
  AcknowledgementAnswerType,
  FaddAcknowledgement as FaddAcknowledgementType,
  useAcknowledgeFaddMutation,
  useGetOrderByIdQuery,
} from "__generated__/types";
import { Button, Modal } from "components";
import { useAtom } from "jotai";
import { useState } from "react";
import { FormattedMessage, useIntl } from "services";
import { acknowledgedDisclaimerAtom } from "services/auth/LegalModal";
import { useNotify } from "services/notification";
import { useOrder } from "services/orders/OrderGuard";

export const FaddAcknowledgement = () => {
  const order = useOrder();
  const { data } = useGetOrderByIdQuery({
    variables: {
      id: order.id!,
    },
    fetchPolicy: "cache-and-network",
  });
  const { formatMessage } = useIntl();
  const notify = useNotify();
  const [isOpen, setIsOpen] = useState(true);
  const deliveryService = order.services.find((s) => s.type === "DELIVERY");
  const faddRequiredStates = [
    FaddAcknowledgementType.RequiredInitial,
    FaddAcknowledgementType.RequiredFinal,
  ];
  const faddAcknowledgementStatus = data?.order?.faddAcknowledgement!;
  // if the fadd acknowledgement shows, it should show after initial disclaimer has been acknowledged
  const [acknowledgedDisclaimer] = useAtom(acknowledgedDisclaimerAtom);

  const [acknowledgeFadd] = useAcknowledgeFaddMutation({
    refetchQueries: ["GetOrderById"],
  });

  const onAcknowledgement = async (ackAnswer: AcknowledgementAnswerType) => {
    await acknowledgeFadd({
      variables: { ackInput: { orderId: order.id!, answer: ackAnswer } },
      onError: (e) => {
        console.error(e);
        notify("Something went wrong", { variant: "error" });
      },
      onCompleted: () => {
        if (ackAnswer === AcknowledgementAnswerType.Yes) {
          notify("Available for Delivery", { variant: "success" });
        } else {
          notify("Storage Needed", { variant: "warning" });
        }
      },
    });
    setIsOpen(false);
  };

  return (
    <>
      {deliveryService &&
      acknowledgedDisclaimer &&
      faddRequiredStates.includes(faddAcknowledgementStatus) ? (
        <Modal open={isOpen} title="Delivery Acknowledgement" width={800}>
          <Box mb="32px">
            <FormattedMessage
              id={"faddAcknowledgement.message"}
              values={{
                orderDetails: (
                  <strong>{`${order.shipmentTypeLabel} (${order.number})`}</strong>
                ),
                dateTime: (
                  <strong>
                    {formatTime(
                      stringToLocalPlainDateTime(
                        deliveryService.plannedDatetimeRange?.begin!,
                        deliveryService.plannedDatetimeRange?.begin.match(
                          /\[(.*?)\]/
                        )?.[1]
                      ),
                      "dateAtTime"
                    )}
                  </strong>
                ),
              }}
            />
          </Box>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            spacing={4}
          >
            <Button
              onClick={() => onAcknowledgement(AcknowledgementAnswerType.No)}
              size="medium"
              variant="secondary"
              ariaLabel={formatMessage({
                id: "ariaLabels.notAvailableFaddButton",
              })}
            >
              <FormattedMessage id="faddAcknowledgement.notAvailable" />
            </Button>

            <Button
              onClick={() => onAcknowledgement(AcknowledgementAnswerType.Yes)}
              size="medium"
              ariaLabel={formatMessage({
                id: "ariaLabels.availableFaddButton",
              })}
            >
              <FormattedMessage id="faddAcknowledgement.available" />
            </Button>
          </Stack>
        </Modal>
      ) : null}
    </>
  );
};
